/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~quill/dist/quill.bubble.css'; 
// or
@import '~quill/dist/quill.snow.css';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');



.login-alert{
    font-size: 14px;
    color: red;
    text-align: center;
   }
   .dir{
    flex-direction: row-reverse;
}
.my-custom-modal{
    --background:#c0d9e44b;
    --height:50%;
    --width:35%;
}
.my-email-modal{
    --height:50%;
    --width:35%;
    --border-radius:8px
}
body, a, h1, h2, h3, h4, h5, h6, p, ul,span,ion-input,ion-label,input{
    font-family: Roboto, sans-serif;
}
.eye-show {
 
    cursor: pointer;

}
.multiselect-dropdown[_ngcontent-dpe-c48] .dropdown-btn[_ngcontent-dpe-c48] {
    display: inline-block;
    /* border: 1px solid #adadad; */
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
}
::-ms-reveal {
    display: none;
}
::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}
// ***************************************DISPLAY JOBS FOR HOME PAGE AND PRODUCTS PAGE****************************************//
